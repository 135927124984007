<template>
  <label class="custom-checkbox" :class="{'custom-checkbox-disabled': disabled}">
    <input
      type="checkbox"
      class="custom-checkbox-input"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
      :disabled="disabled"
    >
    <span class="custom-checkbox-icon">
      <i class="mdi mdi-check"></i>
    </span>
    <span class="custom-checkbox-label" v-if="label"> {{ label }} </span>
  </label>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: String,
    value: [Boolean, String, Number, Array],
    modelValue: {
      default: '',
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MySelectedValues: [],
    };
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue || this.value === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style scoped lang="scss">

.custom-checkbox{
  position: relative;
  display: flex;align-items: center;
  cursor: pointer;
  &-icon{
    border: 0.4rem solid #FF7979;
    border-radius: 0.4rem;
    width: 3.2rem;
    min-width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: 2.4rem;
      color: #FF7979;
      display: none;
    }
  }
  &-disabled{
    cursor: default;
  }
  &-input{
    position: absolute;
    opacity: 0;
    top: 0;
    &:checked + span{
      background: #FBF0ED;
      i{
        display: inline;
      }
    }
  }
  &-label{
    font-size: 1.6rem;
    margin-left: 1rem;
  }
  @media (max-width: 600px){
    &-icon{
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      border-width: 2px;
      i{
        font-size: 1.8rem;
      }
    }
  }
}
.binance{
  .custom-checkbox{
    &-icon{
      border-color: #FDCA37;
      i{
        color: #FDCA37;
      }
    }
    &-input{
      &:checked + span{
        background: rgba(253, 202, 55, 0.24);
      }
    }
  }
}

</style>
