<template>
  <section class="distribution">
    <div class="distribution-title"> {{ $t('distribution.title') }}</div>
    <div class="distribution-body">
      <p v-html="$t('distribution.paragraph1')"></p>
      <p>
        {{ $t('distribution.paragraph2.part1') }}
        <a target="_blank" href="https://blog.spaceswap.app/tpost/0f3nd0xd11-big-ideas-lead-to-big-profits-the-new-sp">
          {{ $t('distribution.paragraph2.part2') }}
        </a>
        {{ $t('distribution.paragraph2.part3') }}
      </p>
      <ul>
        <li v-for="(text, key) of $t('distribution.list')" :key="key">
          {{ text }}
        </li>
      </ul>
      <p>
        {{ $t('distribution.paragraph3') }}
      </p>
    </div>

    <div class="distribution-table">
      <div class="distribution-table-title">
        {{ $t('distribution.table.eth_title') }}
      </div>
      <div class="distribution-table-body">
        <div class="distribution-table-head">
          <div class="column-offset">{{ $t('distribution.table.holding_period') }}</div>
          <div class="text-center"> {{ $t('distribution.table.distribution_check') }}</div>
          <div class="text-center">{{ $t('distribution.table.tx_hash') }}</div>
          <div class="text-center">{{ $t('distribution.table.date') }}</div>
        </div>
        <div class="distribution-table-row" v-for="(e, i) in eth" :key="i">
          <div class="column-offset distribution-table-column1">
            <div>
              from <a :href="`https://etherscan.io/block/${e[0]}`" target="_blank">{{e[0]}}</a>
            </div>
            <div>
              to <a :href="`https://etherscan.io/block/${e[1]}`" target="_blank">{{e[1]}}</a>
            </div>
          </div>
          <div class="d-flex justify-center">
            <custom-checkbox disabled :value="!!e[3]"/>
          </div>
          <a
            target="_blank"
            :href="`https://etherscan.io/tx/${e[2]}`"
            class="text-center distribution-table-column2">{{shrinkString(e[2], 16)}}</a>

          <div class="d-flex justify-center distribution-table-date"> {{ e[3] }} </div>
        </div>
      </div>
    </div>

    <div class="distribution-table">
      <div class="distribution-table-title">
        {{ $t('distribution.table.bsch_title') }}
      </div>
      <div class="distribution-table-body">
        <div class="distribution-table-head">
          <div class="column-offset">{{ $t('distribution.table.holding_period') }}</div>
          <div class="text-center"> {{ $t('distribution.table.distribution_check') }}</div>
          <div class="text-center">{{ $t('distribution.table.tx_hash') }}</div>
          <div class="text-center">{{ $t('distribution.table.date') }}</div>
        </div>
        <div class="distribution-table-row" v-for="(e, i) in bsc" :key="i">
          <div class="column-offset distribution-table-column1">
            <div>
              from <a :href="`https://bscscan.com/block/${e[0]}`" target="_blank">{{e[0]}}</a>
            </div>
            <div>
              to <a :href="`https://bscscan.com/block/${e[1]}`" target="_blank">{{e[1]}}</a>
            </div>
          </div>
          <div class="d-flex justify-center">
            <custom-checkbox disabled :value="!!e[2]"/>
          </div>
          <a
            target="_blank"
            :href="`https://bscscan.com/tx/${e[4]}`"
            class="text-center distribution-table-column2">{{shrinkString(e[4], 16)}}</a>

          <div class="d-flex justify-center distribution-table-date"> {{ e[2] }} </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomCheckbox from '@/components/CustomCheckbox';
import axios from 'axios';
import Papa from 'papaparse';
import { shrinkString } from '../helpers/filters';

const SHEET_IDS = {
  eth: '1ziIFPGwQRxe06zH4juHttnZvC_OHv_sJyC2VDlbsGUw',
  bsc: '1CQ2nTOFosUsBhAE3_tUHTjHHlRv72bJJV_5kS6RttRc',
};

export default {
  name: 'Distribution',
  components: { CustomCheckbox },
  data() {
    return {
      eth_values: [],
      eth: [],
      bsc: [],
    };
  },
  computed: {
    network() {
      return this.$store.state.User.chainName;
    },
  },
  async mounted() {
    this.eth = await this.getData('eth');
    this.bsc = await this.getData('bsc');
  },

  methods: {
    shrinkString,
    async getData(net) {
      const url = `https://docs.google.com/spreadsheets/d/${SHEET_IDS[net]}/export?format=csv`;
      const response = await axios.get(url);
      const { data } = Papa.parse(response.data);
      data.shift();
      return data;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

.distribution {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 1.5rem 13rem;

  .column-offset {
    padding-left: 1.6rem;
  }

  &-title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 140%;
    text-align: center;
    color: #283287;
    margin-bottom: 3.2rem;
  }

  &-body {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 180%;
    text-align: justify;
    letter-spacing: 0.01em;
    color: #283287;
    margin-bottom: 6rem;
    word-break: break-word;

    p {
      margin-bottom: 3.2rem;
    }

    a {
      font-weight: 600;
    }

    ul {
      padding: 0;
      margin: 3.2rem 0;
      list-style-type: none;
      font-weight: 600;
    }
  }

  &-table {
    margin-bottom: 8rem;

    &-title {
      font-weight: bold;
      font-size: 3.4rem;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #283287;
      margin-bottom: 4rem;
    }

    &-date{
      font-weight: 600;
      font-size: 2rem;
      line-height: 155%;
      letter-spacing: 0.03em;
      color: #283287;
    }

    &-body {
      background: linear-gradient(103.94deg, #FDFAFB -1.71%, #F7FBFC 45.51%);
      box-shadow: 0.5rem 0.5rem 1rem rgba(255, 255, 255, 0.9),
      -0.1rem -0.1rem 0.5rem rgba(182, 169, 173, 0.3),
      inset -0.2rem -0.2rem 0.2rem rgba(182, 169, 173, 0.2),
      inset 0.1rem 0.1rem 0.4rem rgba(182, 169, 173, 0.2),
      inset -0.2rem -0.2rem 0.4rem rgba(255, 255, 255, 0.9),
      inset 0.1rem 0.1rem 0.2rem rgba(182, 169, 173, 0.2);
      border-radius: 3rem;
      padding: 3.8rem 4.5rem 0.7rem;
    }

    &-head {
      display: grid;
      font-weight: bold;
      font-size: 2.2rem;
      line-height: 140%;
      color: #283287;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      border-bottom: 0.2rem solid #E0E1F0;
      padding-bottom: 2rem;
    }

    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      padding: 0.3rem 0;

      &:not(:last-child) {
        border-bottom: 0.2rem dashed #E0E1F0;
      }
    }

    &-column1 {
      font-weight: 600;
      font-size: 2rem;
      line-height: 155%;
      letter-spacing: 0.03em;
      color: #283287;
    }

    &-column2 {
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 155%;
      letter-spacing: 0.03em;
      color: #7478A0;
    }

    .three-columns {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }

  @media (max-width: 600px) {
    &-title {
      font-size: 2.7rem;
    }

    &-body {
      font-size: 1.8rem;
    }

    &-table {
      &-head {
        font-size: 1.45rem;
      }

      &-title {
        font-size: 2.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &-body {
        padding: 2.7rem 1.6rem 2.1rem;
      }

      &-column1 {
        padding-left: 0 !important;
        font-size: 1.45rem;
      }

      &-column2, &-date {
        font-size: 1.45rem;
      }
    }
    .column-offset {
      padding-left: 0;
    }
  }
}
.binance{
  .distribution{
    &-body{
      color: #FFFFFF;
    }
    &-title{
      color: #FFFFFF;
    }
    &-table{
      &-title, &-head, &-column1, &-column-2{
        color: #FFFFFF;
      }
      &-date{
        color: white;
      }
      &-body{
        background: linear-gradient(180deg, #1A1A20 0%, #181822 100%);
        box-shadow: 5px 5px 15px #26262F, -2px -2px 20px #23232D,
        inset -2px -2px 10px rgba(22, 22, 26, 0.6),
        inset 2px 2px 20px rgba(23, 24, 33, 0.2),
        inset -2px -2px 6px #171724,
        inset -4px -4px 20px rgba(0, 0, 0, 0.06);
      }
    }
  }
}

</style>
